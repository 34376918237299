import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify, { API } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);
Amplify.configure({
  API: {
    endpoints: [
      {
          name: "AnneHubApi",
          endpoint: "https://rbzigw4u54.execute-api.us-east-2.amazonaws.com/dev"
      },
      {
          name: "CognitoAuth",
          endpoiont: "https://care-hub-emory-test.auth.us-east-2.amazoncognito.com"
      }
    ]
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
