import logo from './logo.svg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import './App.css';
import CallbackPage from './pages/Callback';
import LoginPage from './pages/Login';
import PatientListPage from './pages/PatientList';
import PatientVitalsPage from './pages/PatientVitals';

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/callback" exact component={CallbackPage} />
          <Route path="/callback/" exact component={CallbackPage} />
          <Route path="/login" exact component={LoginPage} />
          <Route path="/login/" exact component={LoginPage} />
          {/* <Route path="/patients" exact component={PatientListPage} />
          <Route path="/patients/" exact component={PatientListPage} />
          <Route path="/patient/:id">
            <PatientVitalsPage />
          </Route>
          <Route path="/patient/:id/">
            <PatientVitalsPage />
          </Route> */}
          <Redirect from="*" to="/login" />
        </Switch>
      </Router>
    </>
  );
}

export default App;
