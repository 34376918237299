import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Container
} from '@material-ui/core';
import { Link } from 'react-router-dom'

const LoginPage = () => {
  return (
    <Container
      maxwidth="sm"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card>
          <CardMedia
            component="img"
            image="/static/logo.png"
            alt="Logo"
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Button 
              variant="contained"
              href="https://care-hub-emory.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Emory&redirect_uri=https://emory.hub.sibelhealth.com/callback&response_type=CODE&client_id=2uj29b0f81n46g1ir7t97nvjsr&scope=openid"
            >
              Login via Emory
            </Button>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default LoginPage;
