import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Container,
  Typography
} from '@material-ui/core';

const CallbackPage = () => {
  // /oauth2/authorize?identity_provider=SAMLTestId&redirect_uri=https://www.sibelhealth.com&response_type=CODE&client_id=652bvokac774cfbf2vmuef2mb1&scope=email openid
  // /oauth2/authorize?identity_provider=Emory&redirect_uri=https://care-hub-emory-test.auth.us-east-2.amazoncognito.com/saml2/idpresponse&response_type=CODE&client_id=652bvokac774cfbf2vmuef2mb1&scope=openid
  // call /oauth2/token of CognitoApi with code for tokens
  // x-www-form-urlencoded
  // {
  //     "code": "82f3030a-c6db-4ad2-81b8-bb07c481bc6e",
  //     "client_id": "652bvokac774cfbf2vmuef2mb1",
  //     "redirect_uri": "http://localhost:3000/callback",
  //     "grant_type": "authorization_code"
  // }
  // fetch('https://example.com/login', {
  //     method: 'POST',
  //     body: new URLSearchParams({
  //         'userName': 'test@gmail.com',
  //         'password': 'Password!',
  //         'grant_type': 'password'
  //     })
  // });
  // dev 
  // client: 652bvokac774cfbf2vmuef2mb1
  // https://care-hub-emory-test.auth.us-east-2.amazoncognito.com/oauth2/token
  // /oauth2/authorize?identity_provider=Emory&redirect_uri=https://care-hub-emory-test.auth.us-east-2.amazoncognito.com/saml2/idpresponse&response_type=CODE&client_id=652bvokac774cfbf2vmuef2mb1&scope=openid
  // http://localhost:3000/callback
  // prod 
  // client: 2uj29b0f81n46g1ir7t97nvjsr
  // https://care-hub-emory.auth.us-east-2.amazoncognito.com/oauth2/token
  // /oauth2/authorize?identity_provider=Emory&redirect_uri=https://care-hub-emory.auth.us-east-2.amazoncognito.com/saml2/idpresponse&response_type=CODE&client_id=2uj29b0f81n46g1ir7t97nvjsr&scope=openid
  // https://emory.hub.sibelhealth.com/callback

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  useEffect(() => {
    fetch('https://care-hub-emory.auth.us-east-2.amazoncognito.com/oauth2/token', {
      method: 'POST',
      body: new URLSearchParams({
        "code": code,
        "client_id": "2uj29b0f81n46g1ir7t97nvjsr",
        "redirect_uri": "https://emory.hub.sibelhealth.com/callback",
        "grant_type": "authorization_code"
      })
    })
    .then(response => {
      console.log(response)
      console.log(response.json())
    })
    .catch(error => {
      console.log(error)
    });
  }, []);

  return (
    <Container
      maxwidth="sm"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Card>
          <CardMedia
            component="img"
            image="/static/logo.png"
            alt="Logo"
          />
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5">
              Under Construction
            </Typography>
          </Box>
        </Card>
      </Box>
    </Container>
  );
};

export default CallbackPage;
